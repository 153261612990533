import React, { useEffect, useState } from "react";
import { Chip, Fab, Container, Box, Grid, Dialog } from "@mui/material";
import { getProjectDetail } from "../../httpRequests/projectList";
import Loading from "../../shared/Loading";
import moment from "moment";
import numberWithCommas from "../../utils/numberWithCommas";
import AddEditProject from "../AddEditProject";
import Breadcrumb from "../../shared/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import {
  UsersIcon,
  RocketLaunchIcon,
  PencilIcon,
  BanknotesIcon,
  BookOpenIcon,
  CodeBracketIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { Wrapper } from "./styles";
import getID from "../../utils/getID";
import { searchJira, getEpicDetail } from "../../httpRequests/jira";
import DEV_JIRA_BOARD from "../../constants/localDevJiraBoard";
import JiraDescription from "./shared/JiraDescription";
import ProgressBarWithPercent from "../../shared/LinearProgressBar";
import Flags from "../../shared/Flags";
import { useAdminState } from "../../context/AdminContext";
import statusChip from "../../utils/statusChip";
const useStyles = makeStyles(() => ({
  svg: {
    position: "relative",
    top: 0,
    marginRight: 5,
    width: 18,
  },
  edit: {
    width: 20,
  },
}));

const ProjectDetail = ({ id }) => {
  const classes = useStyles();
  const [detail, setDetail] = useState(null);
  const { profile } = useAdminState().login.user || {};
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [epics, setEpics] = useState([]);
  const [jiraData, setJiraData] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjectDetail(id);
        setDetail(response.data);

        setFetching(true);
        const res = await searchJira({
          jql: `summary ~ "\\"[${getID(id)}]\\"" AND issuetype = Epic`,
          fields: "summary, project",
        });

        const filteredKeys = res.data.issues
          .filter((jira) => DEV_JIRA_BOARD.includes(jira.fields.project.key))
          .map((jira) => jira.key);

        setEpics(filteredKeys);
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, refetch]);

  useEffect(() => {
    const fetchEpicDetails = async () => {
      try {
        if (epics.length > 0) {
          const epicDetailsArray = [];
          setFetching(true);
          // Loop through each epic and fetch its details
          for (const epic of epics) {
            const res = await getEpicDetail(epic, {
              fields:
                "aggregateprogress, project, description, status, summary",
            });

            // Add the fetched epic details to the array
            epicDetailsArray.push(res.data);
          }

          setFetching(false);
          setJiraData(epicDetailsArray);
        }
      } catch (error) {
        console.error("Error fetching epic details:", error);
      }
    };

    fetchEpicDetails();
  }, [epics]);

  const formatDate = (date) => moment(date, "DD/MM/YYYY").format("DD MMM YYYY");

  if (!detail || fetching) return <Loading />;

  return (
    <Container className="container">
      <Wrapper>
        <div className="page-header">
          <h2>Project Detail</h2>
          <div className="flex">
            <Breadcrumb
              page="Detail"
              link={`project-details?id=${id}`}
              detail
            />
          </div>
        </div>

        <div className="project-detail">
          {(profile?.email === "parama.paramagul@devglobal.lotuss.org" ||
            profile?.email === "parama.paramagul@lotuss.com") && (
            <Fab
              color="primary"
              aria-label="edit"
              className="add-project"
              onClick={() => setOpen(true)}
            >
              <PencilIcon className={classes.edit} />
            </Fab>
          )}

          <div className="top-section">
            <div className="flex top">
              <h2 className="flex">
                {detail.projectName}
                {detail.domain && (
                  <Chip
                    className="domain"
                    color="error"
                    label={detail.domain}
                    size="small"
                  />
                )}
                {detail.tags && (
                  <Chip
                    className="domain"
                    color="primary"
                    label={detail.tags}
                    size="small"
                  />
                )}
                {detail.country && <Flags data={detail.country} />}
              </h2>
              {statusChip(detail.stage)}
            </div>

            <p className="tpm">
              <UsersIcon className={classes.svg} />
              PM/PO: {detail.tpmPo}
              <span>
                <UsersIcon className={classes.svg} />
                SDM(s): {detail.sdm}
              </span>
            </p>
          </div>

          <div className="detail-container">
            {detail.releasedDate && (
              <h3 className="released">
                <span>
                  <RocketLaunchIcon /> <b>Released:</b>{" "}
                  {moment(detail.releasedDate, "DD/MM/YYYY").format(
                    "DD MMMM YYYY"
                  )}
                </span>
              </h3>
            )}

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <BookOpenIcon /> Project Detail
                      </h4>
                      <div className="flex no-border type">
                        <h4 className="uppercase no-border">
                          {detail?.category}
                        </h4>
                      </div>
                    </div>

                    <div className="detail-item no-border">
                      {detail?.detail || (
                        <div className="jira-description">
                          <JiraDescription
                            data={
                              jiraData &&
                              (jiraData[0]?.fields?.description?.content ||
                                jiraData[1]?.fields?.description?.content)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={4}>
                  <div className="summary-item">
                    <h4>
                      <BanknotesIcon /> Budget Summary
                    </h4>
                    <div className="detail-item flex no-border">
                      <div className="item ontrack">
                        <p>IC Project Code</p>
                        <h3>{detail.icProjectCode || "N/A"}</h3>
                      </div>
                      <div className="item inprogress">
                        <p>Budget Amount</p>
                        <h3>
                          {numberWithCommas(detail?.originalBizCaseCost || 0) ||
                            "N/A"}{" "}
                          THB
                        </h3>
                      </div>
                    </div>
                  </div>
                </Grid>

                {jiraData?.length > 1 && (
                  <Grid item xs={12}>
                    <div className="summary-item">
                      <h4>
                        <CodeBracketIcon /> Status by Team(s)
                      </h4>
                      <div className="detail-item flex no-border team-summary">
                        <table>
                          <thead>
                            <tr>
                              <th>Epic</th>
                              <th>Progress</th>
                              <th className="right">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jiraData &&
                              jiraData.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <a
                                      href={`https://lotusretails.atlassian.net/browse/${item.key}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item.fields.summary}
                                      <span>- [{item.fields.project.key}]</span>
                                    </a>
                                  </td>
                                  <td className="bar">
                                    <ProgressBarWithPercent
                                      percent={
                                        item?.fields?.aggregateprogress
                                          ?.percent || 0
                                      }
                                    />
                                  </td>
                                  <td className="right">
                                    {item.fields.status.name}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <div className="summary-item">
                    <h4>
                      <CodeBracketIcon /> Effort (Man-Month)
                    </h4>
                    <div className="detail-item flex no-border">
                      {!detail?.effort ||
                      detail.effort.length === 0 ||
                      detail?.effort[0]?.type === "" ? (
                        <p>No Data</p>
                      ) : (
                        detail.effort.map((item, index) =>
                          item?.type && item?.amount ? (
                            <div className="item ontrack" key={index}>
                              <p>{item.type}</p>
                              <h3>{item.amount}</h3>
                            </div>
                          ) : null
                        )
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="summary-item">
                    <h4>
                      <CalendarDaysIcon /> Plan
                    </h4>
                    <div className="detail-item flex no-border">
                      {["startDate", "finishDate", "estimatedGoLive"].map(
                        (dateKey) => (
                          <div className="item border" key={dateKey}>
                            <p>
                              {dateKey === "estimatedGoLive"
                                ? "Expected Go-Live"
                                : dateKey === "startDate"
                                ? "Dev Start"
                                : "Dev Finish"}
                            </p>
                            <h3>{formatDate(detail[dateKey])}</h3>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="summary-item">
                    <h4>
                      <PencilIcon /> Note
                    </h4>
                    <div className="detail-item no-border">
                      <p>{detail?.note}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Wrapper>

      <Dialog maxWidth="md" onClose={() => setOpen(false)} open={open}>
        <AddEditProject
          id={id}
          setOpen={setOpen}
          title={`[${detail.projectId.slice(-3)}] ${detail.projectName}`}
          action="edit"
          detail={detail}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </Dialog>
    </Container>
  );
};

export default ProjectDetail;
